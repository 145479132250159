import { Col, Layout } from "antd";
import styled, { keyframes } from "styled-components";

const { Header, Sider } = Layout;

export const WrapperLayout = styled(Layout)`
  min-height: 100vh;
  width: 100vw;
`;

export const ContentLayout = styled(Layout)`
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  /* padding: 80px 0 0 0; */
`;

export const WrapperSider = styled(Sider)`
  & > span {
    display: none;
  }
`;

//NavBar

export const WrapperHeader = styled(Header)`
  display: flex;
  width: 100vw;
  flex-direction: row;
  text-align: left;
  font-variant: tabular-nums;
  font-feature-settings: "tnum", "tnum";
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px !important;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: 0 2px 8px #c8ccd1;
  z-index: 102;
  box-sizing: border-box;
  flex: 0 0 auto;
  color: var(--purple);
  background-color: var(--dark);
  padding: 0px 20px 0px 20px;
  height: 64px;
  position: sticky;
  top: 0px;
`;

export const WrapperActionsRight = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

//LoginLayout
